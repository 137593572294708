<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6" :xxl="4">
          <a-form-item label="微信支付单号">
            <a-input
              v-decorator="['out_trade_no', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入微信支付单号"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="关联平台订单号">
            <a-input
              v-decorator="['order_no', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入关联平台订单号"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="支付用户">
            <a-input
              v-decorator="['pay_user', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入支付用户"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入联系电话"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12" :xxl="8">
          <a-form-item label="交易时间">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>
        <a-col :span="4" :xxl="4">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchWechatFlows',
  data() {
    return {
      loadingUserOptions: false,
      form: this.$form.createForm(this, { name: 'wechat_flow_search' })
    }
  },
  created() {
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
